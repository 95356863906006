import React from "react";
import { Link } from "gatsby";
import { Column, Stack } from "@src/components/extended";
import * as T from "@src/styles/piltri";
import { FaqList } from "@src/ui/FAQ/FAQList";
import trophy from "@src/www/monster-of-the-year-2025/assets/trophy.png";

interface FAQItem {
  question: string;
  answer: string;
}

const faqContent: FAQItem[] = [
  {
    question: "Who can participate in the contest?",
    answer:
      "This contest is open to all US K-12 students! Whether you’re a budding artist or just love being creative, we can’t wait to see what you’ll design.",
  },
  {
    question: "Is there a cost to participate?",
    answer:
      "Not at all! Joining the contest is completely free. All you need is your imagination and your creative energy.",
  },
  {
    question: "Can I submit multiple designs?",
    answer:
      "We’d love to see all your ideas, but each participant can submit just one design. So make it your best and let your creativity shine!",
  },
  {
    question: "Can teachers or parents assist in the design?",
    answer:
      "Absolutely! While the design should be created by the student, teachers and parents are welcome to provide support, inspiration, and help with the submission process.",
  },
  {
    question: "How will the winners be chosen?",
    answer:
      "Our judges will carefully review every submission and pick semifinalists based on creativity, originality, and how well the design fits into the ClassDojo world. Then, the finalists will be selected through public voting from March 14 to April 1—so make sure to rally your friends and family to vote!",
  },
  {
    question: "Will my design be used outside of this contest?",
    answer:
      "Your design belongs to you, but by entering the contest, you’re giving us permission to share it as part of the contest or on ClassDojo platforms. If you’re the winner, your monster will also be brought to life in Dojo Islands for everyone to enjoy!",
  },
  {
    question: "Do I need to be a ClassDojo user to enter?",
    answer:
      "Nope! You don’t need to have a ClassDojo account to participate in the contest. Just use the entry form to submit your creation.",
  },
  {
    question: "Who do I contact for questions or issues?",
    answer:
      'We’re here to help! If you have any questions or need assistance, feel free to reach out to us at <a href="mailto:submissions@classdojo.com">submissions@classdojo.com</a>.',
  },
];

const MOTYFAQSection: React.FC = () => {
  return (
    <Stack
      flexDirection={["column", "row"]}
      justifyContent="space-between"
      marginBottom={60}
      css={{ a: { color: "#8348F1" } }}
    >
      <Column css={{ maxWidth: 400 }}>
        <T.Headline1 css={{ marginBottom: 20 }}>FAQ</T.Headline1>
        <T.Body2 css={{ flexGrow: 1 }}>
          See the full <Link to="/monster-of-the-year-2025/rules">rules and conditions</Link>. Email{" "}
          <a href="mailto:submissions@classdojo.com">submissions@classdojo.com</a> with any questions.
        </T.Body2>
        <img src={trophy} alt="trophy" css={{ maxWidth: 300 }} />
      </Column>
      <FaqList faqs={faqContent} />
    </Stack>
  );
};

export default MOTYFAQSection;
