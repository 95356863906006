import React from "react";
import { FAQItem } from "./FAQItem";
import { Column } from "@src/components/extended";

export interface FaqItem {
  question: string;
  answer: string;
}

interface FrequentQuestionsProps {
  faqs: FaqItem[];
}

export const FaqList = (props: FrequentQuestionsProps) => {
  const { faqs } = props;

  return (
    <Column maxWidth={600}>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </Column>
  );
};
